<template>
  <div class="py-4 container-fluid">
    <div class="row vh-40" v-if="apps.length == 5">
      <div class="col py-5 mt-5 text-center">
        <h4 class="mt-5 pt-3s">
          <span class="fa fa-info-circle fa-2x"></span>
        </h4>
        <h4 class="mt-4">You can create maxium of 5 apps in open beta.</h4>
        <router-link :to="'/apps'" class="btn btn-primary btn-md mt-3"
          >View Apps</router-link
        >
      </div>
    </div>
    <div class="row px-4 align-items-center" v-else>
      <div class="col-3 text-center">
        <Box3d v-if="!appName" width="80" height="80" fill="#000000" />
        <Avatar v-else :size="80" variant="pixel" :name="appName" />
        <h3 class="mt-3">{{ appName || "Create an App" }}</h3>
        <h5 class="text-secondary font-weight-normal">
          Creating Apps help you classify and isolate data storage.
        </h5>
      </div>
      <div class="text-center col-9">
        <div class="mb-5 multisteps-form">
          <!--progress bar-->
          <div class="row">
            <div class="mx-auto my-5 col-12 col-lg-8">
              <div class="multisteps-form__progress">
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="User Info"
                  :class="activeStep >= 0 ? activeClass : ''"
                  @click="activeStep = 0"
                >
                  <span>About</span>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Address"
                  :class="activeStep >= 1 ? activeClass : ''"
                  @click="activeStep = 1"
                >
                  <span>Instances</span>
                </button>
                <button
                  class="multisteps-form__progress-btn"
                  type="button"
                  title="Order Info"
                  :class="activeStep === 2 ? activeClass : ''"
                  @click="activeStep = 2"
                >
                  <span>Create App</span>
                </button>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="m-auto col-11">
              <form class="multisteps-form__form">
                <!--single form panel-->
                <div
                  v-if="activeStep === 0"
                  class="p-5 bg-white card multisteps-form__panel border-radius-xl js-active position-relative"
                  data-animation="FadeIn"
                >
                  <div class="text-center row">
                    <div class="mx-auto col-10">
                      <h5 class="">What is your app called?</h5>
                      <p>Let's start with the basic information.</p>
                    </div>
                  </div>
                  <div class="multisteps-form__content">
                    <div class="mt-3 row">
                      <div class="mt-4 col-12 col-sm-12 mt-sm-0 text-start">
                        <label>App Name (3 - 63 chars)</label>
                        <input
                          class="mb-3 multisteps-form__input form-control"
                          type="text"
                          placeholder="FateBook"
                          v-model="appName"
                          id="appName"
                        />
                        <div class="form-group mb-3">
                          <label
                            >App Identifier (3 - 63 chars) | No Spaces. -,_
                            allowed</label
                          >
                          <input
                            class="multisteps-form__input form-control"
                            type="text"
                            placeholder="fate-book"
                            v-model="appIdentifier"
                            id="appIdentifier"
                          />
                          <small
                            id="AppIdentifierHelp"
                            class="form-text text-xs text-primary font-weight-bolder"
                          >
                            <span class="fa fa-info-circle"></span> App
                            identifier will also reserve a subdomain for the
                            CDN. This cannot be changed later.<br />
                            {{
                              appIdentifier || "--identifier--"
                            }}.exadrivecdn.com
                          </small>
                        </div>
                        <label>Describe your app (3 - 127 chars)</label>
                        <input
                          class="mb-3 multisteps-form__input form-control"
                          type="text"
                          placeholder="FateBook is for faters."
                          v-model="appDescription"
                          id="appDescription"
                        />
                      </div>
                    </div>
                    <div class="mt-4 button-row d-flex mt-5">
                      <button
                        class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
                        type="button"
                        title="Next"
                        @click="nextStep()"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <!--single form panel-->

                <div
                  :class="activeStep === 1 ? activeClass : ''"
                  class="p-5 bg-white card multisteps-form__panel border-radius-xl"
                  data-animation="FadeIn"
                >
                  <div class="text-center row">
                    <div class="mx-auto col-10">
                      <h5 class="">
                        Where will {{ appName || "this App" }} run?
                      </h5>
                      <p>
                        We will optimize the file delivery based on user agent.
                      </p>
                    </div>
                  </div>
                  <div class="multisteps-form__content">
                    <div class="mt-4 row">
                      <div class="col-sm-2 ms-auto form-check form-check">
                        <input
                          id="btncheck1"
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkOnTheWeb"
                        />
                        <label
                          class="px-4 py-3 border-2 btn btn-lg btn-outline-light"
                          for="btncheck1"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-globe"
                            class="fa-2x"
                            style="color: gray"
                          />
                        </label>
                        <h6>On the Web (SDK Avaiable)</h6>
                      </div>
                      <div class="col-sm-2 form-check opacity-5">
                        <input
                          id="btncheck2"
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkAndroid"
                          disabled
                        />
                        <label
                          class="px-4 py-3 border-2 btn btn-lg btn-outline-light"
                          for="btncheck2"
                        >
                          <font-awesome-icon
                            icon="fa-brands fa-android"
                            class="fa-2x"
                            style="color: green"
                          />
                        </label>
                        <h6>Android (SDK Soon)</h6>
                      </div>
                      <div class="col-sm-2 me-auto form-check opacity-5">
                        <input
                          id="btncheck3"
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkIOS"
                          disabled
                        />
                        <label
                          class="px-4 py-3 border-2 btn btn-lg btn-outline-light"
                          for="btncheck3"
                        >
                          <font-awesome-icon
                            icon="fa-brands fa-apple"
                            class="fa-2x"
                            style="color: black"
                          />
                        </label>
                        <h6>iOS (SDK Soon)</h6>
                      </div>
                    </div>
                    <div class="mt-4 button-row d-flex mt-5">
                      <button
                        class="mb-0 btn bg-gradient-light js-btn-prev"
                        type="button"
                        title="Prev"
                        @click="prevStep"
                      >
                        Prev
                      </button>
                      <button
                        class="mb-0 btn bg-gradient-dark ms-auto js-btn-next"
                        type="button"
                        title="Next"
                        @click="nextStep"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <!--single form panel-->

                <div
                  :class="activeStep === 2 ? activeClass : ''"
                  class="p-5 bg-white card multisteps-form__panel"
                  data-animation="FadeIn"
                >
                  <div class="text-center row">
                    <div class="mx-auto col-12">
                      <h5 class="">Does everything look good?</h5>
                      <p class="text-sm">
                        Please verify the details before creating the app.
                      </p>
                    </div>
                  </div>
                  <div class="multisteps-form__content text-start mt-4">
                    <div class="row">
                      <div class="col-md-6">
                        <table class="table table-striped">
                          <tr>
                            <td class="font-weight-bold">App Name</td>
                            <td class="px-4">{{ appName }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">App Identifier</td>
                            <td class="px-4">{{ appIdentifier }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">App Description</td>
                            <td class="px-4">{{ appDescription }}</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">App will run</td>
                            <td class="px-4">On the Web</td>
                          </tr>
                          <tr>
                            <td class="font-weight-bold">API KEY</td>
                            <td class="text-sm text-primary px-4">
                              ...will be generated after App creation.
                            </td>
                          </tr>
                        </table>
                      </div>
                      <hr class="my-3" />

                      <div class="mb-3 mt-3">
                        <p class="font-weight-bold">Please verify reCaptcha:</p>
                        <RecaptchaV2
                          @widget-id="handleWidgetId"
                          @error-callback="handleErrorCalback"
                          @expired-callback="handleExpiredCallback"
                          @load-callback="handleLoadCallback"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mt-4 button-row d-flex col-12">
                        <button
                          class="mb-0 btn bg-gradient-light js-btn-prev"
                          type="button"
                          title="Prev"
                          @click="prevStep"
                        >
                          Prev
                        </button>
                        <button
                          class="mb-0 btn bg-gradient-dark ms-auto"
                          type="button"
                          title="Send"
                          @click="createApp"
                        >
                          Create App
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import Toast from "primevue/toast";
import { createApp } from "vue";
import axios from "axios";
import Box3d from "../../components/Icon/Box3d.vue";
import { RecaptchaV2 } from "vue3-recaptcha-v2";
import Avatar from "vue-boring-avatars";

export default {
  name: "Wizard",
  components: {
    Toast,
    RecaptchaV2,
    Box3d,
    Avatar,
  },
  mounted() {
    //fetch all apps
    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.apps = response.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      apps: [],
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 2,
      appName: "",
      appIdentifier: "",
      appDescription: "",
      instances: {
        checkOnTheWeb: {
          status: false,
          hostnames: [],
        },
        checkOnAndroid: {
          status: false,
          namespaces: [],
        },
        checkOnIos: {
          status: false,
          namespaces: [],
        },
      },
      checkOnTheWeb: false,
      checkAndroid: false,
      checkIOS: false,
      captchaResponseMessage: "",
      captchaResponse: "",
    };
  },
  methods: {
    handleWidgetId(widgetId) {
      // console.log("Widget ID: ", widgetId);
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    createApp() {
      if (!this.checkOnTheWeb) {
        return;
      }

      if (this.checkOnTheWeb) {
        this.instances = {
          checkOnTheWeb: {
            status: true,
            hostnames: [],
          },
          checkOnAndroid: {
            status: false,
            namespaces: [],
          },
          checkOnIos: {
            status: false,
            namespaces: [],
          },
        };
      }
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/org/apps/createApp",
        data: {
          appName: this.appName,
          appDescription: this.appDescription,
          appIdentifier: this.appIdentifier,
          instances: this.instances,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
        headers: { Authorization: localStorage.getItem("jwt") },
      })
        .then((response) => {
          console.log(response);
          this.$router.push("/apps");
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: err.response.data.errors,
            life: 3000,
          });
        });
    },
    async nextStep() {
      //step validations
      console.log("active step:" + this.activeStep);
      if (this.activeStep == 0) {
        document.getElementById("appName").classList.remove("is-valid");
        document.getElementById("appName").classList.remove("is-invalid");
        document.getElementById("appIdentifier").classList.remove("is-valid");
        document.getElementById("appIdentifier").classList.remove("is-invalid");
        document.getElementById("appDescription").classList.remove("is-valid");
        document
          .getElementById("appDescription")
          .classList.remove("is-invalid");

        if (this.appName.length > 63 || this.appName.length < 3) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "App name must be between 3 and 63 chars.",
            life: 3000,
          });

          document.getElementById("appName").classList.add("is-invalid");
          return;
        }
        //app name passed the validation
        document.getElementById("appName").classList.add("is-valid");

        const appIdentifierRegex = /^[a-zA-Z](?!.*[-_]{2})[a-zA-Z0-9_-]{2,62}$/;
        if (!appIdentifierRegex.test(this.appIdentifier)) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "App identifier is invalid.",
          });
          document.getElementById("appIdentifier").classList.add("is-invalid");
          return;
        }
        //check if app identifer is avaiable from backend
        if (!(await this.verifyappIdentifier())) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "App identifier is not available.",
          });
          document.getElementById("appIdentifier").classList.add("is-invalid");
          return;
        }

        //app unique name passed the validation
        document.getElementById("appIdentifier").classList.add("is-valid");
        if (
          this.appDescription.length > 127 ||
          this.appDescription.length < 3
        ) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "App description should be between 3 and 127 chars.",
            life: 3000,
          });
          document.getElementById("appDescription").classList.add("is-invalid");
          return;
        }
        //app description  passed the validation
        document.getElementById("appDescription").classList.add("is-valid");
      }
      if (this.activeStep == 1) {
        if (!this.checkOnTheWeb) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Select at least one locaiton where this app will run.",
            life: 3000,
          });
          return;
        }
      }

      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    async verifyappIdentifier() {
      try {
        const response = await axios.get(
          this.$store.getters["getServerURL"] +
            "/org/apps/verifyAppIdentifier/" +
            this.appIdentifier,
          {
            headers: {
              Authorization: localStorage.getItem("jwt"),
            },
          }
        );
        // console.log(response);
        return response.data;
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to verify App identifier.",
          life: 3000,
        });
        return false;
      }
    },
  },
};
</script>

<style>
.form-check:not(.form-switch) .form-check-input[type="checkbox"]:after {
  transition: opacity 0.25s ease-in-out;
  font-family: sans-serif !important;
  content: "\2713" !important;
}

.multisteps-form__progress-btn {
  color: black !important;
}
.vh-100 {
  height: 100vh;
}
.vh-80 {
  height: 80vh;
}
.vh-60 {
  height: 60vh;
}
.vh-40 {
  height: 40vh;
}
.vh-20 {
  height: 20vh;
}
</style>
