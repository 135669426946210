<template>
  <nav aria-label="breadcrumb">
    <ol
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
      :class="this.$store.state.isRTL ? '' : ' me-sm-6'"
    >
      <li class="text-sm breadcrumb-item">
        <a href="/" class="opacity-3 text-dark">
          <font-awesome-icon :icon="faHouse" :class="textWhite" />
        </a>
      </li>
      <li class="text-sm breadcrumb-item" :class="textWhite">
        <a :class="textWhite" class="opacity-4" href="#">{{
          currentDirectory
        }}</a>
      </li>
    </ol>
  </nav>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "Breadcrumbs",
  components: {
    FontAwesomeIcon,
  },
  props: {
    currentPage: {
      type: String,
      default: "",
    },
    currentDirectory: {
      type: String,
      default: "",
    },
    textWhite: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      faHouse,
    };
  },
};
</script>
