<template>
  <div class="card h-100 mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Monthly usage and invoices:</h6>
        </div>
      </div>
    </div>
    <div class="card-body d-flex">
      <div
        v-if="!invoices"
        class="w-100 text-center justify-content-center align-self-center"
      >
        <h2 class="fa fa-info-circle"></h2>
        <p>No invoices creaded yet.</p>
      </div>
      <ul class="list-group w-100" v-else>
        <li
          v-for="invoice in invoices"
          :key="invoice._id"
          class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="mb-1 text-dark font-weight-bold text-sm">
              {{ getMonth(invoice.month) }}, {{ invoice.year }}
            </h6>
            <span class="text-xs">{{ invoice._id }}</span>
          </div>
          <div class="d-flex align-items-center text-sm">
            <h5>
              {{
                calculatePayable(
                  invoice.dataStorage,
                  invoice.dataStoragePrice,
                  invoice.dataTransfer,
                  invoice.dataTransferPrice
                )
              }}
              $XAP
            </h5>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "InvoiceCard",
  components: {
    SoftButton,
  },
  props: {
    invoices: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    getMonth(numMonth) {
      switch (numMonth) {
        case 0:
          return "January";
        case 1:
          return "February";
        case 2:
          return "March";
        case 3:
          return "April";
        case 4:
          return "May";
        case 5:
          return "June";
        case 6:
          return "July";
        case 7:
          return "August";
        case 8:
          return "September";
        case 9:
          return "October";
        case 10:
          return "November";
        case 11:
          return "December";
      }
    },
    calculatePayable(dS, dSP, dT, dTP) {
      console.log({ dS, dSP, dT, dTP });
      var cost = (dS / (1024 * 1024)) * dSP;
      cost += (dT / (1024 * 1024)) * dTP;
      cost = cost / 1000000;
      return cost.toFixed(8);
    },
  },
};
</script>
