<template>
  <div class="container-fluid mt-4">
    <div class="row">
      <div class="col mb-4">
        <h3><span class="fa fa-file"></span> File Manager</h3>
        <p class="mb-0 font-weight-bold">
          Manage all of your uploaded files here.
        </p>
      </div>
    </div>

    <div class="col-md-12">
      <div class="card card-body p-4">
        <div class="row align-items-center">
          <div class="col-10 my-auto">
            <div class="h-100">
              <h5 class="mb-1">Bucket: {{ app.appName || "-" }}</h5>
              <p class="text-xs text-primary">App ID: {{ app._id || "-" }}</p>
              <p class="text-sm font-weight-bold">
                Public URI Format:
                <code
                  >https://{{
                    app.appIdentifier || "-"
                  }}.exadrivecdn.com/&lt;--path--&gt;/&lt;--filename--&gt;</code
                >
              </p>
              <hr />
              <p class="text-xs">
                Storage Policy:
                <span class="badge badge-primary">redundancy_default</span>
                &nbsp;
                <span class="badge badge-primary">all_public</span> &nbsp;
                <span class="badge badge-primary">overwrite</span>
              </p>
            </div>
          </div>
          <div class="col-2 h-100 text-center" v-if="isApp">
            <button
              class="btn btn-primary btn-md"
              data-bs-toggle="modal"
              data-bs-target="#uploadFileModal"
            >
              + Upload File
            </button>

            <p class="text-xs">* You can also upload using the SDK.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-lg-12 d-flex justify-content-end"></div>
      <div class="col-lg-12 mt-lg-0 mt-4">
        <div class="card mt-4">
          <div class="card-header border-bottom pb-2">
            <h6 class="font-weight-bold">
              <span class="fa fa-info-circle"></span> Total {{ fileCount }}
              {{ fileCount == 1 ? "file" : "files" }} in this bucket.
            </h6>
          </div>

          <div class="card-body p-0 pb-4">
            <div
              class="col-12 d-flex justify-content-center py-8"
              v-if="loading"
            >
              <Spinner />
            </div>
            <div class="table-responsive" v-if="files.length > 0 && !loading">
              <table class="table">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Filename</th>
                    <th scope="col">Directory</th>

                    <th scope="col">Size</th>
                    <th scope="col">Public</th>
                    <th scope="col">Uploaded On</th>
                    <th scope="col">CDN URL</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="file in files" :key="file._id" class="fade-it">
                    <td class="px-4" scope="row" :title="file.originalName">
                      <span class="font-weight-bold"
                        >{{ file.originalName }} </span
                      ><br />
                      <span class="text-xs text-primary"
                        >ID: {{ file._id }}</span
                      >
                    </td>
                    <td scope="row" class="px-4">
                      {{ file.virtualPath }}
                    </td>

                    <td scope="row" class="px-4 text-sm" style="width: 5%">
                      {{ formatBytes(file.fileDetails.size, 2) }}
                    </td>
                    <td scope="row" class="px-4" style="width: 5%">
                      <span class="badge badge-primary badge-sm">{{
                        file.isPublic
                      }}</span>
                    </td>

                    <td scope="row" class="px-4 text-sm" style="width: 15%">
                      {{ file.uploadedOn }}
                    </td>
                    <td class="px-4" style="width: 5%">
                      <button
                        class="btn btn-link btn-sm mb-0 px-0"
                        @click="copyText(file.publicLink)"
                      >
                        <span class="fa fa-copy"></span> Copy URL
                      </button>
                    </td>
                    <td scope="row" class="px-4" style="width: 5%">
                      <a
                        href="#"
                        class="btn btn-link text-danger mb-0 px-0"
                        @click="
                          deleteFile(
                            file._id,
                            file.originalName,
                            file.virtualPath
                          )
                        "
                        ><font-awesome-icon icon="fa-solid fa-trash" />
                        Delete</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <Paginator
                class="mt-3"
                :rows="limit"
                :totalRecords="totalFileCount"
                @page="viewPage"
              />
            </div>
          </div>

          <div
            class="card-body text-center mt-3 py-5"
            v-if="files.length == 0 && !loading"
          >
            <font-awesome-icon
              icon="fa-solid fa-triangle-exclamation"
              class="fa-2x"
            />
            <p class="text-center mt-2">No files in this bucket.</p>
            <p class="text-sm mt-n2">
              You can upload files through this page or through the SDK.
            </p>
          </div>
        </div>
      </div>
    </div>
    <Toast />

    <!-- Uplaod File Modal -->
    <div
      class="modal"
      id="uploadFileModal"
      aria-labelledby="uploadFileModal"
      aria-hidden="true"
      v-if="isApp"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Upload File</h5>
            <button
              type="button"
              class="btn-close text-dark"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="fa fa-close"></span>
            </button>
          </div>
          <div class="modal-body pt-3 text-sm">
            <p><span class="fa fa-warning"></span> Keep in mind:</p>
            <ul>
              <li>File with same name and directory will be overwritten!</li>
              <li>
                All files are <span class="badge badge-primary">Public</span>.
                Be mindful of uploading sensitive files.
              </li>
              <li>Max. file size 8MB - for open beta.</li>
              <li>
                Directoy name must begin with "/" and <b>NOT</b> end with "/"
              </li>
              <li>
                Directoy name can only contain alphabets, numbers, -, and _.
                <br />No white spaces are allowed.
              </li>
            </ul>

            <form
              id="uploadForm"
              class="mt-4"
              role="form"
              @submit.prevent="uploadFile()"
            >
              <label for="fileDirectory" class="form-label"
                >Directory | exDFS (Exa Distributed File System)</label
              >
              <div class="input-group mb-3">
                <span class="input-group-text" id="directoy">
                  <span class="fa fa-home"></span>&nbsp;</span
                >
                <input
                  type="text"
                  name="virtualPath"
                  placeholder="Empty is save to root..."
                  class="form-control"
                  id="fileDirectory"
                  v-model="virtualPath"
                  aria-describedby="directory"
                />
              </div>

              <label for="formFileLg" class="form-label"
                >Select a file to upload</label
              >
              <input
                class="form-control form-control-lg"
                id="formFileLg"
                type="file"
                name="file"
              />
              <button
                type="submit"
                class="btn btn-primary mt-4"
                id="submitFileButton"
              >
                Upload
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup"; // optional
import Row from "primevue/row"; // optional
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import Toast from "primevue/toast";
import Paginator from "primevue/paginator";
import Spinner from "../../components/Spinner.vue";

export default {
  name: "FileManger",
  components: {
    Column,
    ColumnGroup,
    Row,
    Toast,
    Paginator,
    Spinner,
  },
  async mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    // console.log(this.$route.params.id);

    await axios
      .get(
        this.$store.getters["getServerURL"] +
          "/org/apps/file/getUploadedFiles?appID=" +
          this.$route.params.id +
          "&page=" +
          this.page +
          "&limit=" +
          this.limit,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        // console.log(response);
        this.loading = false;
        this.fileCount = response.data.count;
        this.files = response.data.files;
        this.currentPage = response.data.currentPage;
        this.limit = response.data.limit;
        this.totalPages = response.data.pages;
        this.totalFileCount = response.data.count;
      })
      .catch((err) => {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch files.",
          life: 3000,
        });
      });

    //get app
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/org/apps/getApp/" +
          this.$route.params.id,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((resp) => {
        this.app = resp.data;
        this.isApp = true;
      })
      .catch((err) => {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch app.",
          life: 3000,
        });
      });
  },
  data() {
    return {
      fileCount: 0,
      app: {},
      files: [],
      totalPages: 0,
      currentPage: 0,
      limit: 10,
      totalFileCount: 0,
      isApp: false,
      virtualPath: "/",
      loading: true,
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    viewPage(event) {
      // console.log(event);
      this.currentPage = event.page;
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/org/apps/file/getUploadedFiles?appID=" +
            this.$route.params.id +
            "&page=" +
            this.currentPage +
            "&limit=" +
            this.limit,
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.files = response.data.files;
          this.currentPage = response.data.currentPage;
          this.limit = response.data.limit;
          this.totalPages = response.data.pages;
          this.totalFileCount = response.data.count;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Unable to fetch files.",
            life: 3000,
          });
        });
    },
    deleteFile(fileID, fileName, virtualPath) {
      if (
        !confirm(
          "Are you sure you want to delete:\n\n " +
            virtualPath +
            (virtualPath == "/" ? "" : "/") +
            fileName +
            "? \n\nThis action cannot be undone.\nIt can take serveral minutes to remove this from CDN cache."
        )
      ) {
        return;
      }
      axios({
        method: "delete",
        url: this.$store.getters["getServerURL"] + "/org/apps/file/deleteFile",
        headers: {
          Authorization: localStorage.getItem("jwt"),
        },
        data: {
          fileID: fileID,
          virtualPath: virtualPath,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "File Deleted",
            detail: resp.data,
            life: 3000,
          });

          this.fileCount--;
          //delete element from the files object.
          for (var i = 0; i < this.files.length; i++) {
            if (this.files[i]._id == fileID) {
              this.files.splice(i, 1);
              break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Unable to delete file. \n" + fileID + "\n" + err.response.data,
            life: 2000,
          });
        });
    },
    uploadFile() {
      const form = document.getElementById("uploadForm");
      const pathRegex = /^\/([a-zA-Z0-9/_-]*[a-zA-Z0-9_-])?$|^\/$/;
      if (!pathRegex.test(this.virtualPath)) {
        this.$toast.add({
          severity: "error",
          summary: "Path Error",
          detail: "Invalid virtual path",
          life: 2000,
        });
        return;
      }
      let formData = new FormData(form);
      formData.append("appID", this.app._id);
      const button = document.getElementById("submitFileButton");
      button.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...`;
      button.disabled = true;
      axios
        .post(
          this.$store.getters["getServerURL"] + "/org/apps/file/uploadFile",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Uplaoded",
            detail: "File uploaded.",
            life: 2000,
          });
          // console.log(response.data);
          form.reset();
          button.innerHTML = `Upload`;
          button.disabled = false;

          this.fileCount++;

          //delete same file name from the files array. Traverse opposite as File array is sorted by date.
          for (var i = 0; i < this.files.length; i++) {
            if (
              this.files[i].originalName == response.data.originalName &&
              this.files[i].virtualPath == response.data.virtualPath
            ) {
              this.files.splice(i, 1);
              this.fileCount--;
              break;
            }
          }

          this.files.unshift(response.data);

          // setTimeout(() => {
          //   this.$router.go(this.$router.currentRoute);
          // }, 2000);
        })
        .catch((err) => {
          console.log(err);
          button.innerHTML = `Upload`;
          button.disabled = false;
          this.$toast.add({
            severity: "error",
            summary: "Upload Error",
            detail: err.response.data.errors,
            life: 2000,
          });
        });
    },
    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "CDN URL copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },
  },
};
</script>

<style>
.p-paginator-page.p-paginator-page-selected {
  background: lightgray !important;
  color: black !important;
}

@-webkit-keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
@-moz-keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
@keyframes blue-fade {
  from {
    background: rgb(201, 223, 255);
  }
  to {
    background: #fff;
  }
}
.fade-it {
  -webkit-animation: blue-fade 1s ease-in-out 0s;
  -moz-animation: blue-fade 1s ease-in-out 0s;
  -o-animation: blue-fade 1s ease-in-out 0s;
  animation: blue-fade 1s ease-in-out 0s;
}

td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
