<template>
  <div class="card h-100" :class="`bg-${cardColor}`">
    <div class="text-center card-body">
      <h1 :class="`text-${color}`">
        <span class="text-lg ms-n2">{{ prefix }}</span>
        {{ "" }}
        <countTo
          :start-val="0"
          :end-val="count"
          :duration="duration"
          v-if="alwaysCount"
        ></countTo>
        <span v-else>{{ count }}</span>
        {{ "" }}
        <span class="text-lg ms-n2">{{ suffix }}</span>
      </h1>
      <h6 class="mb-0 font-weight-bolder" :class="`text-${color}`">
        {{ title }}
      </h6>
      <p class="mb-0 text-sm opacity-8" :class="`text-${color}`">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import countTo from "vue-count-to/src";

export default {
  name: "DefaultCounterCard",
  components: {
    countTo,
  },
  props: {
    count: {
      type: [String, Number],
      required: true,
    },
    alwaysCount: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 2000,
    },
    cardColor: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "success",
    },
  },
};
</script>
