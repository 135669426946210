<template>
  <navbar btn-background="bg-gradient-dark" />
  <section
    class="pt-5 page-header align-items-start min-vh-50 pb-10"
    :style="{
      'background-image': 'url(' + BGGrid + ')',
      'background-size': 'cover',
      'background-position': 'top',
    }"
  >
    <span class="mask opacity-9" style="background-color: #0a0a0a"></span>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-5">
          <h1 class="mt-5 pt-5 text-white text-center">🙏 Welcome Back!</h1>
          <p class="alert alert-primary text-white text-xs mt-4 text-center">
            <span class="fa fa-info-circle"></span> Only Accounts approved under
            the Accelerate program will be activated. Please contact Exa
            Protocol team.
          </p>

          <p
            v-if="this.$route.query.register == 'true'"
            class="text-xs text-white alert alert-success font-weight-bold"
          >
            Account creation successful! <br />The team will now active the
            account if you have been approved for the Accelerate program.
          </p>

          <p
            v-if="this.$route.query.passwordChange == 'true'"
            class="text-xs text-white alert alert-success font-weight-bold"
          >
            Password changed successfully. <br />You can now login with the new
            password.
          </p>
        </div>
      </div>
    </div>
  </section>
  <div class="container">
    <div class="row mt-lg-n9 mt-md-n9 mt-n9 justify-content-center">
      <div class="col-md-4">
        <div class="card z-index-0">
          <div class="text-center card-header pb-0 pt-4">
            <h5>Sign In</h5>
            <p>Sign in with your email and password</p>
          </div>

          <div class="card-body">
            <form role="form" class="text-start" @submit.prevent="signIn()">
              <div class="mb-3">
                <input
                  class="form-control"
                  id="email"
                  type="email"
                  placeholder="Email"
                  name="email"
                  autocomplete="username"
                  required
                  v-model="email"
                />
              </div>

              <div class="mb-3">
                <input
                  class="form-control"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  maxlength="16"
                  minlength="8"
                  required
                  placeholder="Password"
                  v-model="password"
                />
              </div>

              <div>
                <ul
                  v-if="validationMessages.length > 0"
                  class="text-danger text-sm text-left mt-4"
                >
                  <li v-for="message in validationMessages" :key="message">
                    {{ message }}
                  </li>
                </ul>

                <div class="d-flex justify-content-center mb-3 mt-4">
                  <RecaptchaV2
                    @widget-id="handleWidgetId"
                    @error-callback="handleErrorCalback"
                    @expired-callback="handleExpiredCallback"
                    @load-callback="handleLoadCallback"
                  />
                </div>

                <p class="text-xs text-center mt-4">
                  You will remain signed in for the next 30 Days.
                </p>
                <soft-button
                  class="mb-2 btn-primary"
                  variant="gradient"
                  color="info"
                  full-width
                  type="submit"
                  >Sign in
                </soft-button>
              </div>
            </form>
            <div class="mb-2 text-center position-relative">
              <p
                class="px-3 mb-2 text-sm bg-white font-weight-bold text-secondary text-border d-inline z-index-2"
              >
                or
              </p>
            </div>
            <div class="text-center">
              <router-link
                :to="'/authentication/signup'"
                class="btn btn-dark w-100 mt-2 mb-4"
                full-width
                >Sign up
              </router-link>

              <router-link
                :to="'/authentication/reset'"
                class="btn btn-link"
                full-width
                >Forgot your password?
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftButton from "@/components/SoftButton.vue";
import axios from "axios";
import { RecaptchaV2 } from "vue3-recaptcha-v2";
import BGGrid from "@/assets/img/bg-grid.svg";

import Toast from "primevue/toast";

import { mapMutations } from "vuex";
import { bottom } from "@popperjs/core";
export default {
  name: "SigninBasic",
  components: {
    Navbar,
    AppFooter,
    SoftInput,
    SoftSwitch,
    SoftButton,
    RecaptchaV2,
    Toast,
  },
  data() {
    return {
      BGGrid: BGGrid,
      email: "",
      password: "",
      emailValidationError: false,
      passwordValidationError: false,
      validationMessages: [],
      captchaResponse: "",
      captchaResponseMessage: "",
    };
  },
  mounted() {},
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    handleWidgetId(widgetId) {
      console.log("Widget ID: ", widgetId);
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    validateEmail() {
      var regex = /^[a-zA-Z0-9_.+\-]+[\x40][a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.email)) {
        console.log("Email is invalid.");
        this.validationMessages.push("Email is invalid.");
        this.emailValidationError = true;
      } else {
        this.emailValidationError = false;
      }
    },
    validatePassword() {
      var passwordVal = true;
      if (this.password.length < 8 || this.password.length > 16)
        passwordVal = false;

      if (!passwordVal) {
        this.validationMessages.push(
          "Password is invalid. \nPassword must be between 8 and 16 characters."
        );
        this.passwordValidationError = true;
      } else {
        this.passwordValidationError = false;
      }
    },
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    signIn() {
      //reset errors
      this.validationMessages = [];
      this.emailValidationError = false;
      this.passwordValidationError = false;

      this.validateEmail();
      this.validatePassword();

      if (this.emailValidationError || this.passwordValidationError) {
        return;
      }

      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/auth/signin",
        data: {
          email: this.email,
          password: this.password,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((response) => {
          console.log(response);
          var user = response.data;
          localStorage.setItem("jwt", user.token);
          localStorage.setItem("loggedIn", "true");
          localStorage.setItem("expiresIn", user.expiresIn);
          localStorage.setItem("userID", user.user.userID);
          localStorage.setItem("userName", user.user.name);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          console.log(error);
          this.captchaResponseMessage = "";
          this.captchaResponse = false;
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.message,
            life: 3000,
          });
        });
    },
  },
};
</script>
