<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>

  <main class="mt-0 main-content main-content-bg">
    <section class="min-vh-75">
      <div class="container">
        <div class="row">
          <div class="mx-auto col-lg-5 col-md-7">
            <div class="mb-4 card z-index-0 mt-sm-12 mt-9" v-if="!verifyToken">
              <div class="pt-4 pb-1 text-center card-header">
                <h4 class="mb-1 font-weight-bolder">Reset password</h4>
                <p class="mb-0">
                  You will receive an e-mail in maximum 60 seconds
                </p>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="resetPassword()">
                  <div class="mb-3">
                    <input
                      class="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      autocomplete="username"
                      v-model="email"
                      required
                    />
                  </div>

                  <div class="d-flex justify-content-center mb-3 mt-4">
                    <RecaptchaV2
                      @widget-id="handleWidgetId"
                      @error-callback="handleErrorCalback"
                      @expired-callback="handleExpiredCallback"
                      @load-callback="handleLoadCallback"
                    />
                  </div>

                  <div class="text-center">
                    <soft-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="dark"
                      full-width
                      size="lg"
                      type="submit"
                      >Send
                    </soft-button>

                    <Toast />
                  </div>
                </form>
              </div>
            </div>

            <div class="mb-4 card z-index-0 mt-sm-12 mt-9" v-else>
              <div class="pt-4 pb-1 text-center card-header">
                <h4 class="mb-1 font-weight-bolder">Change Password</h4>
                <p class="mb-0">
                  Reset token is valid. You can now change your password.
                </p>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="changePassword()">
                  <div class="mb-3">
                    <input
                      class="form-control"
                      type="password"
                      placeholder="New Password"
                      name="new-password"
                      autocomplete="new-password"
                      v-model="newPassword"
                      required
                    />
                    <input
                      class="form-control mt-3"
                      type="password"
                      placeholder="Confirm New Password"
                      name="new-password"
                      autocomplete="new-password"
                      v-model="confirmNewPassword"
                      required
                    />
                  </div>

                  <div class="d-flex justify-content-center mb-3 mt-4">
                    <RecaptchaV2
                      @widget-id="handleWidgetId"
                      @error-callback="handleErrorCalback"
                      @expired-callback="handleExpiredCallback"
                      @load-callback="handleLoadCallback"
                    />
                  </div>

                  <div class="text-center">
                    <soft-button
                      class="my-4 mb-2"
                      variant="gradient"
                      color="dark"
                      full-width
                      size="lg"
                      type="submit"
                      >Change Password
                    </soft-button>

                    <Toast />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftButton from "@/components/SoftButton.vue";
const body = document.getElementsByTagName("body")[0];
import Toast from "primevue/toast";
import axios from "axios";
import { RecaptchaV2 } from "vue3-recaptcha-v2";

import { mapMutations } from "vuex";
export default {
  name: "ResetBasic",
  components: {
    Navbar,
    AppFooter,
    SoftButton,
    RecaptchaV2,
    Toast,
  },
  data() {
    return {
      email: "",
      captchaResponse: "",
      captchaResponseMessage: "",
      emailValidationError: "",
      validationMessages: "",
      verifyToken: null,
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  async mounted() {
    if (this.$route.query.token) {
      try {
        var response = await axios.get(
          this.$store.getters["getServerURL"] +
            "/auth/forgotPassword/resetToken/" +
            this.$route.query.token
        );
      } catch (err) {
        console.log(err);
        this.verifyToken = false;
        return;
      }

      console.log(response);
      this.verifyToken = true;
      return;
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-200");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    handleWidgetId(widgetId) {
      console.log("Widget ID: ", widgetId);
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },

    validateEmail() {
      var regex = /^[a-zA-Z0-9_.+\-]+[\x40][a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.email)) {
        console.log("Email is invalid.");
        this.validationMessages.push("Email is invalid.");
        this.emailValidationError = true;
      } else {
        this.emailValidationError = false;
      }
    },
    resetPassword() {
      this.validationMessages = [];
      this.validateEmail();

      if (this.emailValidationError) {
        return;
      }

      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/auth/forgotPassword/generateEmailLink",
        data: {
          email: this.email,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((response) => {
          console.log(response);
          this.$router.push("/authentication/signin/basic/?register=true");
        })
        .catch((error) => {
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.errors,
            life: 5000,
          });
        });
    },
    changePassword() {
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/auth/forgotPassword/resetToken/" +
          this.$route.query.token,
        data: {
          new_password: this.newPassword,
          confirm_new_password: this.confirmNewPassword,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((response) => {
          console.log(response);
          this.$router.push(
            "/authentication/signin/basic/?passwordChange=true"
          );
        })
        .catch((error) => {
          console.log(error);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.errors,
            life: 5000,
          });
        });
    },
  },
};
</script>
