<template>
  <div></div>
</template>
<script>
export default {
  name: "Logout",
  mounted() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("expiresIn");
    localStorage.removeItem("userID");
    localStorage.removeItem("userName");
    this.$router.push("/authentication/signin");
  },
};
</script>
