<template>
  <!-- -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- -->
  <footer class="py-5 footer">
    <div class="container">
      <div class="row">
        <div class="mt-1 col-8">
          <p class="mb-0 text-secondary">
            Created with <span class="fa fa-heart text-danger"></span> by Exa
            Protocol. Copyright © {{ new Date().getFullYear() }} exaprotocol.com
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>
