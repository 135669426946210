<template>
  <div class="card h-100 mt-4">
    <div class="card-header pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Your credit history:</h6>
        </div>
      </div>
    </div>
    <div class="card-body d-flex">
      <div
        v-if="!credits"
        class="w-100 text-center justify-content-center align-self-center"
      >
        <h2 class="fa fa-info-circle"></h2>
        <p>
          No credits added to this account. <br />You can apply for grants to
          get credits.
        </p>
        <a href="#" class="btn btn-primary">Apply Now</a>
      </div>
      <ul class="list-group w-100" v-else>
        <li
          v-for="credit in credits"
          :key="credit._id"
          class="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg"
        >
          <div class="d-flex flex-column">
            <h6 class="text-dark mb-1 font-weight-bold text-sm">
              {{ credit.creditType.description }}
            </h6>
            <span class="text-xs"
              >Expires On:
              {{ formatDate(credit.creditType.expiresOn) }} (dd/mm/yyy)</span
            >
          </div>
          <div class="d-flex align-items-center text-md">
            {{ credit.creditType.credits }} $XAP
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";

export default {
  name: "Credits",
  components: {
    SoftButton,
  },
  props: {
    credits: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    formatDate(unixTimestamp) {
      var date = new Date(unixTimestamp * 1000);
      return date.toLocaleDateString("en-GB");
    },
  },
};
</script>
