<template>
  <div class="py-4 container-fluid">
    <div class="row mt-4">
      <div class="col-9">
        <div class="alert alert-primary text-white text-sm" role="alert">
          <span class="fa fa-warning"></span> ExaDrive Management Console is
          currently open beta. Expect experimental features, ephimeral data
          storage, and CDN downtime.
        </div>
      </div>
    </div>
    <div class="row h-100">
      <div class="col-lg-5">
        <div class="row">
          <div class="col-lg-12 col-sm-12 mt-4">
            <div class="card">
              <div class="card-header pb-2 border-bottom">
                <h6 class="font-weight-bold">
                  <span class="fa fa-star"></span> Quick Links
                </h6>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <ul class="quick-links">
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp; <a href="/apps">View your Apps</a>
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span> &nbsp;
                        <a href="/apps/create">Create App</a>
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span> &nbsp;
                        <a href="/buckets">View your Buckets</a>
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span> &nbsp;
                        <a href="/account/settings">Settings</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <ul class="quick-links">
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp;
                        <a
                          href="https://www.npmjs.com/package/exadrive-sdk"
                          target="_blank"
                          >Get the SDK</a
                        >
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp;
                        <a href="https://exa-drive.gitbook.io/" target="_blank"
                          >Docs</a
                        >
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp;
                        <a
                          href="https://exaprotocol.medium.com/"
                          target="_blank"
                          >Quick Start Guides</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <default-counter-card
              :count="formatBytes(dataTransfer).num || 0"
              :suffix="formatBytes(dataTransfer).suffix || ''"
              color="dark"
              title="Egress this month"
              description="Updated every 5 mins."
            />
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <default-counter-card
              :count="formatBytes(dataStorage).num || 0"
              :suffix="formatBytes(dataStorage).suffix || ''"
              color="dark"
              title="Ingress this month"
              description="Updated every 5 mins."
            />
          </div>

          <div class="col-lg-6 col-sm-12 mt-4">
            <default-counter-card
              :count="response.fileCount || 0"
              suffix=""
              color="dark"
              title="Total Files"
              description="Updated at every file upload."
            />
          </div>
          <div class="col-lg-6 col-sm-12 mt-4">
            <default-counter-card
              :count="response.appCount || 0"
              suffix=""
              color="dark"
              title="Total Apps"
              description="Max 5 apps allowed in Beta."
            />
          </div>
        </div>
      </div>

      <div class="col-lg-7 pt-4">
        <div class="card h-100">
          <div class="card-header pb-2 border-bottom">
            <h6 class="font-weight-bold">
              <span class="fa fa-upload"></span> Last 10 Uploaded Files (across
              all Apps)
            </h6>
          </div>
          <div class="card-body d-flex p-0">
            <div class="table-responsive w-100" v-if="files.length > 0">
              <table class="table">
                <thead class="table-light bg-light">
                  <th>Filename</th>
                  <th>Directory</th>
                  <th>Uploaded On</th>
                  <th>Size</th>
                  <th>CDN URL</th>
                </thead>
                <tbody>
                  <tr v-for="file in files" :key="file._id">
                    <td :title="file.originalName" class="px-4">
                      {{ file.originalName }}
                    </td>
                    <td :title="file.virtualPath" class="px-4">
                      {{ file.virtualPath }}
                    </td>
                    <td class="px-4 text-sm">{{ file.uploadedOn }}</td>
                    <td class="px-4 text-sm">
                      {{
                        formatBytes(file.fileDetails.size).num +
                        " " +
                        formatBytes(file.fileDetails.size).suffix
                      }}
                    </td>
                    <td class="px-4">
                      <button
                        class="btn btn-link btn-sm mb-0 p-2"
                        @click="copyText(file.publicLink)"
                      >
                        <span class="fa fa-copy"></span>
                        Copy URL
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="w-100 text-center justify-content-center align-self-center"
              v-else
            >
              <h4><span class="fa fa-info-circle fa-2x"></span></h4>
              <h4 class="h-100">No recent files.</h4>
              <p>You can upload files through SDK or Buckets.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Toast />
  </div>
</template>
<script>
import DefaultCounterCard from "@/examples/Cards/DefaultCounterCard.vue";
import DefaultProjectCard from "../../components/DefaultProjectCard.vue";
import Tag from "primevue/tag";
import axios from "axios";
import Panel from "primevue/panel";
import Toast from "primevue/toast";
export default {
  name: "DashboardDefault",
  components: {
    DefaultCounterCard,
    DefaultProjectCard,
    Tag,
    Panel,
    Toast,
  },
  mounted() {
    axios
      .get(this.$store.getters["getServerURL"] + "/dashboard/getSummary", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.response = response.data;
        this.dataStorage = response.data.latestInvoice.dataStorage;
        this.dataTransfer = response.data.latestInvoice.dataTransfer;
        this.files = response.data.latestFiles;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      response: {},
      dataStorage: 0,
      dataTransfer: 0,
      files: [],
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return {
        num: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        suffix: sizes[i],
      };
    },
    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "CDN URL copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },
  },
};
</script>

<style scoped>
td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quick-links {
  list-style: none;
  padding: 0;
}

.quick-links li {
  padding: 10px;
}

.quick-links li a:hover {
  color: initial;
}
.quick-links li:hover {
  background: #eee;
}
</style>
