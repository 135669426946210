<template>
  <div class="container-fluid my-4">
    <div class="row mt-4">
      <div class="col-9 mt-4">
        <div class="alert alert-primary text-white text-sm" role="alert">
          <span class="fa fa-warning"></span> ExaDrive Management Console is
          currently open beta. Expect experimental features, ephimeral data
          storage, and CDN downtime.
        </div>
      </div>
    </div>
    <div class="row">
      <h4 class="mt-4 font-weight-bolder">Summary</h4>
      <div class="col-lg-10">
        <div class="row mt-4">
          <div class="col-xl-4 mb-xl-0 mb-4">
            <div class="card h-100">
              <div class="card-body">
                <p>Payable this month:</p>
                <h2 class="text-primary">{{ calcualtePayable() || 0 }} $XAP</h2>
                <p class="text-sm text-primary">
                  If you have credits, this will be adjusted from them.
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-8">
            <div class="row h-100">
              <div class="col-md-4">
                <default-counter-card
                  :count="formatBytes(currentInvoice.dataTransfer).num || 0"
                  :suffix="
                    formatBytes(currentInvoice.dataTransfer).suffix || ''
                  "
                  color="dark"
                  title="Egress this month"
                  description="Updated every 5 mins."
                />
              </div>
              <div class="col-md-4">
                <default-counter-card
                  :count="formatBytes(currentInvoice.dataStorage).num || 0"
                  :suffix="formatBytes(currentInvoice.dataStorage).suffix || ''"
                  color="dark"
                  title="Ingress this month"
                  description="Updated every 5 mins."
                />
              </div>
              <div class="col-md-4">
                <default-counter-card
                  :count="getExpiringCredits() || 0"
                  suffix="$XAP"
                  color="dark"
                  :alwaysCount="false"
                  title="Credits remaining"
                  description="*Approximate. Credits that are expiring first."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <h4 class="mt-4 font-weight-bolder">Credit and Invoice history</h4>
      <div class="col-lg-5">
        <invoice-card :invoices="invoices" />
      </div>
      <div class="col-lg-5">
        <credits-card :credits="credits" />
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import NavPill from "./components/NavPill.vue";

import InvoiceCard from "./components/InvoiceCard.vue";
import BillingCard from "./components/BillingCard.vue";

import DefaultCounterCard from "../../../examples/Cards/DefaultCounterCard.vue";
import CreditsCard from "./components/CreditsCard.vue";
import axios from "axios";
import Toast from "primevue/toast";
export default {
  name: "Billing",
  components: {
    NavPill,

    InvoiceCard,
    BillingCard,

    DefaultCounterCard,
    CreditsCard,
    Toast,
  },
  mounted() {
    //get current invoice
    axios
      .get(this.$store.getters["getServerURL"] + "/org/getCurrentInvoice", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.currentInvoice = response.data;
        console.log(response.data);
      })
      .catch((err) => {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch current invoice.",
          life: 5000,
        });
      });

    //get all invoices
    axios
      .get(this.$store.getters["getServerURL"] + "/org/getAllInvoices", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.invoices = response.data;
        console.log(response.data);
      })
      .catch((err) => {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch invoices.",
          life: 5000,
        });
      });

    //get credits
    axios
      .get(this.$store.getters["getServerURL"] + "/org/getCredits", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.credits = response.data;
        console.log(this.credits);
      })
      .catch((err) => {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch credits.",
          life: 5000,
        });
      });
  },
  data() {
    return {
      credits: [
        {
          creditType: {
            credit: 0,
          },
        },
      ],
      invoices: [],
      currentInvoice: {},
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return {
        num: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        suffix: sizes[i],
      };
    },
    getExpiringCredits() {
      var index = 0;
      if (this.credits.length == 1) {
        return this.credits[index].creditType.credits;
      }

      for (var i = 1; i < this.credits.length; i++) {
        if (
          this.credits[i].creditType.expiresOn <
          this.credits[index].creditType.expiresOn
        ) {
          index = i;
        }
      }
      console.log(this.credits[index]);
      return parseFloat(this.credits[index].creditType.credits).toFixed(2);
    },
    calcualtePayable() {
      var cost =
        (this.currentInvoice.dataStorage / (1024 * 1024)) *
        this.currentInvoice.dataStoragePrice;
      cost +=
        (this.currentInvoice.dataTransfer / (1024 * 1024)) *
        this.currentInvoice.dataTransferPrice;
      cost = cost / 1000000;
      return cost.toFixed(8);
    },
  },
};
</script>
