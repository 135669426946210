<template>
  <div class="container-fluid">
    <section class="mt-3 pt-2">
      <div class="row mt-4">
        <div class="col-9">
          <div class="alert alert-primary text-white text-sm" role="alert">
            <span class="fa fa-warning"></span> ExaDrive Management Console is
            currently open beta. Expect experimental features, ephimeral data
            storage, and CDN downtime.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="text-left col-md-8 me-auto mt-2">
          <h4 class="font-weight-bolder">All of your Apps</h4>
          <p>
            You can create, manage, and update API Keys for your apps from here.
          </p>
        </div>
      </div>

      <div class="row mt-2">
        <div
          class="col-12 mt-10 mb-10 d-flex justify-content-center"
          v-if="loading"
        >
          <Spinner />
        </div>
        <template v-if="!loading">
          <div
            class="mb-4 col-lg-4 col-md-4"
            v-for="app in apps"
            :key="app._id"
          >
            <app-card
              :active="app.active"
              :appID="app._id"
              :title="app.appName"
              :description="app.appDescription"
              :lastUpdated="app.updatedOn"
              :apiKey="app.apiKey"
              :apiKeyGenTime="app.apiKeyGenTime"
              :appIdentifier="app.appIdentifier"
            />
          </div>

          <div class="mb-4 col-lg-4 col-md-4" v-if="apps.length < 5">
            <place-holder-card :title="{ text: 'New App', variant: 'h5' }" />
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import PlaceHolderCard from "./components/PlaceHolderCard.vue";
import AppCard from "./components/AppCard.vue";
import Avatar from "vue-boring-avatars";
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
export default {
  name: "Apps",
  components: {
    AppCard,
    PlaceHolderCard,
    Avatar,
    Spinner,
  },
  data() {
    return {
      showMenu: false,
      apps: [],
      loading: true,
    };
  },

  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();

    //fetch all apps
    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        console.log(response);
        this.apps = response.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
