<template>
  <div class="card mb-4">
    <div class="mx-4 card-header d-flex justify-content-center">
      <Avatar :size="80" variant="pixel" :name="appName" />
    </div>
    <hr class="my-3 horizontal dark" />
    <div class="p-3 card-body text-center">
      <h6 class="mb-0 text-center">{{ appName }}</h6>
      <p class="text-xs">{{ appId }}</p>
      <code class="text-xs">{{ appIndentifier }}.exadrivecdn.com</code>

      <hr class="my-3 horizontal dark" />
      <a
        class="btn btn-link"
        @click="this.$router.push('/buckets/filemanager/' + appId)"
        >View Bucket</a
      >
    </div>
  </div>
</template>

<script>
import Avatar from "vue-boring-avatars";

export default {
  name: "BucketCard",
  components: {
    Avatar,
  },
  props: {
    appName: {
      type: String,
      required: true,
    },
    appId: {
      type: String,
    },
    appIndentifier: {
      type: String,
      default: "",
    },
    appDescription: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>
