
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
//primevue
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Lara from '@primevue/themes/lara';
import Nora from '@primevue/themes/nora';

import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';


/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { install } from "vue3-recaptcha-v2";

import VueGtag from "vue-gtag";

/* add icons to the library */
library.add(fas, fab, far)

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(VueTilt);
app.use(VueSweetalert2);
app.use(SoftUIDashboard);
app.use(PrimeVue, {
     theme: {
          preset: Aura,
          options: {
               darkModeSelector: '.my-app-dark'
          }
     },
});
app.use(ToastService);
app.use(ConfirmationService);
app.use(install, {
     sitekey: "6Led1HImAAAAAAXBXMxfP2Ap5byl4o20lQllyb1x",
     cnDomains: false, // Optional, If you use in China, set this value true
});

app.config.productionTip = false;
app.use(VueGtag, {
     config: { id: "G-4K2QT4BZ28" }
});

app.mount("#app");
